@import '../../../defaults';

footer {
  border-top: 2px solid $white;

  li {
    line-height: $line-height-lg;
  }
}

.anw-footer-newsletter-headline {
  line-height: 1.5rem;
}

.anw-g-widget {
  span[class*='anw-rating-star-'] {
    color: #e4721d;
  }

  .anw-g-widget-text {
    font-size: 12px;
  }

  .anw-g-widget-count {
    margin-top: 0.1rem;
  }

  .anw-g-widget-border {
    border-top: 4px #34a853 solid;
  }

  .anw-g-widget-stars {
    margin-bottom: -4px;
    margin-top: -4px;
  }
}
